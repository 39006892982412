<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>  
        {{t('Escuelas')}}
      </v-card-title>
      <school-list />
    </v-card>
  </div>
</template>

<script>

import { mdiHospitalBoxOutline } from '@mdi/js'
import SchoolList from '@/components/Schools/SchoolList'
import { useUtils } from '@core/libs/i18n'


export default {
  components: {
    SchoolList
},
  setup() {
    const { t } = useUtils()
    return {
      t,
      // icons
      icons: {
        mdiHospitalBoxOutline
      },
    }
  },

}
</script>