<template>
  <v-dialog
    v-model="showingDeleteSchoolModal"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{$t('¿Estás seguro de eliminar la escuela')}} '{{ school.name }}'?
      </v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn 
          color="tertiary" 
          outlined 
          @click="close()"
        >
          {{$t('Cancelar')}}
        </v-btn>         
        <v-btn
          color="error"
          outlined
          @click="deleteSchool()"
        >
          {{$t('Eliminar')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
    return {
      showingDeleteSchoolModal: false,
      school: {}
    }
  },

  methods: {
    openModal(school) {
      this.showingDeleteSchoolModal = true
      this.school = school
    },

    close() {
      this.showingDeleteSchoolModal = false
    },

    async deleteSchool() {
      try {
        const response = await this.$api.deleteSchool(this.school.id)

        this.$emit('reload-school-list')
        this.close()
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>