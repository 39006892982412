<template>
  <div>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        :aria-label="t('Buscar por nombre de escuela')"
        :placeholder="t('Buscar por nombre de escuela')"
        outlined
        hide-details
        dense
        class="me-3 mb-4"
        v-model="searchQuery"
        @keyup="handleSearch"
      >
      <!-- v-model="searchQuery" -->
        <!-- @keyup="handleSearch" -->
      </v-text-field>
      <v-spacer></v-spacer>
      <v-select
					style="width: 0px"
					class="mb-4 mr-1"
					:items="[{name: '-', id: -1},...countryList]"
					hide-details="true"
					:label="t('País')"
					item-text="name"
					item-value="name"
					outlined
					dense
					v-model="countryFilter"
					@change="setFilterCountry"
					>
          <template v-slot:selection="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>

          <template v-slot:item="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>
        </v-select>
				<v-select v-if="getUserRole !== 3"
					style="width: 0px"
					class="mb-4"
					:items="[{name: '-', id: -1},...regionList]"
					hide-details="true"
					:label="t('Region')"
					item-text="name"
					item-value="name"
					outlined
					dense
					v-model="stateFilterRegion"
					@change="setFilterRegion"
					></v-select>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
      <v-btn
        color="primary"
        class="mb-4 me-3"
        @click="$refs.school_form_modal.openModal()"
        v-if="getUserRole === 0 || getUserRole === 1 || getUserRole === 2"
        >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
      <span>{{t('Crear Escuela')}}</span>
      </v-btn>

    </div>
    </v-card-text>
      <!-- table -->
      <v-data-table
      :no-data-text="getError ? getError : $t('No hay datos disponibles.')"
      :headers="tableColumns"
      :items="schoolListTable"
      :page="current_page"
      :server-items-length="totalSchoolListTable"
      :loading="loading"
      @update:page="changingSchoolsPage($event)"
      @update:items-per-page="changingSchoolsPerPage($event)"
      @update:sort-desc="sortingPage($event)"
      :footer-props="{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': `${$t('Items por página')}`,
          'page-text': `{0}-{1} ${$t('de')} {2}`,
        }"
      >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.modality`]="{item}">
        <span v-if="item.modality !== null && item.modality >=0"> {{ `${modalityParser[item.modality]}` }} </span>
        <span v-else> {{ '-'}} </span>
      </template>

      <template #[`item.type_of_management`]="{item}">
        <span v-if="item.type_of_management !== null && item.type_of_management >=0"> {{ `${managementParser[item.type_of_management]}` }} </span>
        <span v-else> {{ '-'}} </span>
      </template>

      <template #[`item.level`]="{item}">
        <span v-if="item.level !== null && item.level >=0"> {{ `${levelParser[item.level]}` }} </span>
        <span v-else> {{ '-'}} </span>
      </template>

      <template #[`item.region`]="{item}">
        {{ `${parseRegion(item.region)}` }}
      </template>
      <!-- action -->
      <template #[`item.actions`]="{ item }">

        <v-btn 
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="$refs.school_form_modal.openModal(item.id)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Editar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </v-btn>

        <v-btn 
          class="me-2 icon-with-label"
          color="error"
          text
          @click="$refs.delete_school_modal.openModal(item)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Eliminar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      <template #[`item.progress`]="{ item }">
        <v-btn 
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="() => $router.push(`/schools/results/${item.id}`)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Ver')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      </v-data-table>

    <school-form-modal
      ref="school_form_modal"
      @reload-school-list="getSchoolList()"
    />

    <delete-school-modal
      ref="delete_school_modal"
      @reload-school-list="getSchoolList()"
    />

  </v-card>
  </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import { ref } from '@vue/composition-api';
import SchoolFormModal from '@/components/Schools/SchoolFormModal';
import DeleteSchoolModal from '@/components/Schools/DeleteSchoolModal';
import { useUtils } from '@core/libs/i18n'
import countriesParserMixin from '@/utils/countriesParserMixin';
import debounce from '../../plugins/debounce.js'

import {
mdiDeleteOutline,
mdiSquareEditOutline,
mdiDotsVertical,
mdiPencilOutline,
mdiEyeOutline
} from '@mdi/js'


export default {
components: {
  SchoolFormModal,
  DeleteSchoolModal
},
data: ()  => {
  return {
    regionList: [],
    countryList: [],
    countryFilter: -1,
    current_page: 1,
    page_size: 10,
    sortDesc: false,
    stateFilterRegion: -1,
    getError: null,
    loading: false,
  }
},
mixins: [countriesParserMixin],
methods: {
  async getSchoolList() {
    this.loading = true
    try {
      const query = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.searchQuery,
        region: this.stateFilterRegion,
        country: this.countryFilter,
        order: this.sortDesc
      }
      const response = await this.$api.getSchoolList(query)
      this.schoolListTable = response.results
      this.totalSchoolListTable = response.count
    } catch (error) {
      if(error?.response?.status === 403) {
				this.getError = error?.response?.data?.detail
			}
    } finally {
      this.loading = false
    }
  },
  changingSchoolsPage(e) {
    this.current_page = e
    this.getSchoolList()
  },
  changingSchoolsPerPage(e) {
    this.page_size = e
    this.getSchoolList()
  },
  sortingPage(e) {
    this.sortDesc = e
    this.getSchoolList()
  },
  handleSearch: debounce(function() {
      this.current_page = 1;
      this.getSchoolList();
    }, 400),

  setFilterRegion() {
    this.current_page = 1
      this.getSchoolList()
  },

  async getRegionList() {
    try {
      const query = { paginated: false, country: this.countryFilter, }
      const response = await this.$api.getRegionList(query)
      this.regionList = response
    } catch(e) {
      console.log(e)
    }
  },

  async getCountryList() {
    try {
      const response = await this.$api.getCountryList({ paginated: false })
      this.countryList = response
    } catch(e) {
      console.log(e)
    }
  },

  setFilterCountry() {
    this.current_page = 1
    this.getSchoolList()
    this.stateFilterRegion = -1
    this.getRegionList()
  },

  parseRegion(id) {
    let regionName = ''
    this.regionList.forEach(el => {
      if(el.id === id) {
        regionName = el.name
      }
    })
    return regionName
  }
},
mounted() {
this.getSchoolList()
this.getCountryList()
if(this.getUserRole !== 3)this.getRegionList()
},
computed: {
  tableColumns() {
    return [
    { text: this.$t('Nombre'), value: 'name', align: 'center'},
    { text: this.$t('Modalidad'), value: 'modality', align: 'center', sortable: false },
    { text: this.$t('Tipo de gestión'), value: 'type_of_management', align: 'center', sortable: false },
    { text: this.$t('Nivel'), value: 'level', align: 'center', sortable: false },
    { text: this.$t('Region'), value: 'region', align: 'center', sortable: false },
    { text: this.$t('Acciones'), value: 'actions', align: 'center', sortable: false },
    { text: this.$t('Progreso'), value: 'progress', align: 'center', sortable: false }
    ];
  },
  getUserRole() { return this.$store.getters['session/getUserRole']() },
  modalityParser() {
    const parser = {
      0: this.$t('Educación regular'),
      1: this.$t('Educación especial'),
      2: this.$t('Educación domiciliaria hospitalaria'),
      3: this.$t('Centro de primera infancia'),
    }
    return parser
  },
  managementParser() {
    const parser = {
      0:this.$t('Público'),
      1:this.$t('Privado')
    }
    return parser
  },
  levelParser() {
    const parser = {
      0: this.$t('Preescolar'),
      1: this.$t('Escolar primario'),
      2: this.$t('Escolar secundario'),
    }
    return parser
  }
},
setup() {
  const totalSchoolListTable = ref(0);
  const schoolListTable = ref([]);
  const searchQuery = ref('');
  const { t } = useUtils()

  return {
    totalSchoolListTable,
    schoolListTable,
    searchQuery,
    t,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiEyeOutline
    },
  }
},
}
</script>

<style scoped>
.no-uppercase {
text-transform: unset !important;
}
</style>
